import companyStore from 'store/settings/company/companyStore'
import { RegisterOrganization } from 'src/widgets/RegisterOrganization/RegisterOrganization'
import { BtnSize } from 'components/Button/Button'
import { SizedBox } from 'components/SizedBox'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { NoCompleteRoutes } from 'src/routes/routes'
import styles from 'src/widgets/RegisterOrganization/styles.module.scss'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import registerStore from 'store/registerStore'
import { InputCheckboxGroup } from 'components/Input/InputCheckbox/InputCheckboxGroup'
import { observer } from 'mobx-react-lite'
import { useRegisterHubspotMember } from 'src/hooks/useRegisterHubspotMember'

export const RegisterCompanyInfoB = observer(() => {
  const { setCompanyData, setExpectedMessageTypeMap, expectedMessageTypeMap } =
    registerStore
  const navigate = useNavigate()
  const onContinue = async (companyName: string, employees: string | null) => {
    try {
      setCompanyData({
        companyName,
        employees,
      })
      navigate(NoCompleteRoutes.register2)
    } catch (e) {
      console.error(e)
    }
  }

  useRegisterHubspotMember()

  return (
    <RegisterOrganization
      progress={50}
      btnPrimarySize={BtnSize.large}
      btnPrimaryText={'Continue'}
      onContinue={onContinue}
      initCompanyName={companyStore.companyName}
      additionalContent={
        <>
          <SizedBox height={32} />

          <div className={styles.deliverText}>
            How you’ll deliver your messages?
          </div>
          <SizedBox height={8} />
          <InputCheckboxGroup variant2>
            {Array.from(expectedMessageTypeMap).map(([id, item]) => (
              <InputCheckbox
                key={id}
                label={item.title}
                checked={item.checked}
                onChecked={() => setExpectedMessageTypeMap(id)}
              />
            ))}
          </InputCheckboxGroup>
        </>
      }
    />
  )
})
