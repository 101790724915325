import { makeAutoObservable } from 'mobx'
import { ExpectedMessageType } from 'src/generated/graphql'

const ExpectedMessageTypeObj = {
  [ExpectedMessageType.Sms]: 'SMS',
  [ExpectedMessageType.Ringless]: 'Ringless',
  [ExpectedMessageType.Voice]: 'Automated Calls',
}

type IExpectedMessageTypeItem = {
  id: ExpectedMessageType
  title: string
  checked: boolean
}

class RegisterStore {
  constructor() {
    makeAutoObservable(this)
    const companyDataString = localStorage.getItem('companyData')
    if (companyDataString) {
      const companyData = JSON.parse(companyDataString)
      this.companyName = companyData.companyName
      this.employees = companyData.employees
      this.expectedMessageTypeMap = new Map(companyData.deliveredMap)
    }
  }

  companyName = ''
  employees: string | null = ''
  expectedMessageTypeMap: Map<ExpectedMessageType, IExpectedMessageTypeItem> =
    new Map(
      Object.entries(ExpectedMessageTypeObj).map(([id, title]) => [
        id,
        { id, title, checked: false },
      ])
    ) as Map<ExpectedMessageType, IExpectedMessageTypeItem>

  get messageTypes(): ExpectedMessageType[] {
    const messageTypes: ExpectedMessageType[] = []
    this.expectedMessageTypeMap.forEach((item, key) => {
      if (item.checked) {
        messageTypes.push(key)
      }
    })
    return messageTypes
  }
  setCompanyData = ({
    companyName,
    employees,
  }: {
    companyName: string
    employees: string | null
  }) => {
    this.companyName = companyName
    this.employees = employees
    localStorage.setItem(
      'companyData',
      JSON.stringify({
        companyName: this.companyName,
        employees: this.employees,
        expectedMessageTypeMap: this.expectedMessageTypeMap,
      })
    )
  }

  setExpectedMessageTypeMap = (label: ExpectedMessageType) => {
    const oldItem = this.expectedMessageTypeMap.get(label)
    if (oldItem) {
      this.expectedMessageTypeMap.set(label, {
        ...oldItem,
        checked: !oldItem?.checked,
      })
    }
  }
}

export default new RegisterStore()
