import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  AccountIntegrationType,
  useSetupIntegrationMutation,
} from 'src/generated/graphql'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'

export const IntegrationsOAuth = () => {
  const [setupIntegration] = useSetupIntegrationMutation()
  const [searchParams] = useSearchParams()

  const { type } = useParams()

  useEffect(() => {
    const code = searchParams.get('code')
    const external = searchParams.get('external')
    let oAuthType: AccountIntegrationType | undefined
    if (type === 'keap') {
      oAuthType = AccountIntegrationType.Keap
    }
    if (type === 'hubspot') {
      oAuthType = AccountIntegrationType.HubSpot
    }
    if (type === 'leadconnector') {
      oAuthType = AccountIntegrationType.LeadConnector
    }
    if (oAuthType) {
      if (external) {
        setupIntegration({
          variables: {
            input: {
              oauth2Integration: {
                code,
                type: oAuthType,
              },
            },
          },
        })
          .catch(console.error)
          .finally(() => {
            window.close()
          })
      } else {
        const codeString = JSON.stringify({
          code,
          oAuthType,
        })
        localStorage.setItem('OAuthData', codeString)
        window.close()
      }
    }
  }, [])
  return <AbsoluteLoader />
}
