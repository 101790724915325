import { SizedBox } from 'components/SizedBox'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { InputCheckboxGroup } from 'components/Input/InputCheckbox/InputCheckboxGroup'
import styles from './styles.module.scss'
import { SimpleTextarea } from 'components/SimpleTextarea/SimpleTextarea'

export type IFeedbackContentProps = {
  title: string
  comment: string
  setComment: (value: string) => void
  answers: Array<[key: string, value: string]>
  selectedAnswer: string | null
  setSelectedAnswer: (id: string) => void
}

export const FeedbackContent = ({
  answers,
  selectedAnswer,
  setSelectedAnswer,
  title,
  comment,
  setComment,
}: IFeedbackContentProps) => {
  return (
    <div>
      <span className={'s2 medium gray1 mb8'}>{title}</span>
      <InputCheckboxGroup>
        {answers.map(([id, value]) => (
          <InputCheckbox
            key={id}
            label={value}
            checked={selectedAnswer === id}
            onChecked={() => setSelectedAnswer(id)}
            radio
          />
        ))}
      </InputCheckboxGroup>
      {selectedAnswer ? (
        <div className={styles.wrapAnswer}>
          <SimpleTextarea
            label={'Is there anything else we should to know? (optional)'}
            value={comment}
            onChangeValue={setComment}
          />
        </div>
      ) : (
        <SizedBox height={121} />
      )}
    </div>
  )
}
