import { AppRoute } from 'src/util/route'
import { UnauthorizedRoutes } from 'src/routes/routes'
import { ForgotPassword } from 'src/routes/auth/forgot/forgot'
import { SignUp } from 'src/routes/auth/signUp/signUp'
import { Invite } from 'src/routes/auth/invite/invite'
import { BannedAccount } from 'src/routes/auth/bannedAccount/bannedAccount'
import { Login } from 'src/routes/auth/login/login'

export const unauthorizedRoutes: AppRoute[] = [
  {
    path: UnauthorizedRoutes.login,
    Element: Login,
  },
  {
    path: UnauthorizedRoutes.ban,
    Element: BannedAccount,
  },
  {
    path: UnauthorizedRoutes.forgot,
    Element: ForgotPassword,
  },
  {
    path: UnauthorizedRoutes.signup,
    Element: SignUp,
  },
  {
    path: UnauthorizedRoutes.invite,
    Element: Invite,
  },
]
