import { IntegrationsCard } from 'src/routes/settings/integrations/content/card/IntegrationsCard'
import styles from './styles.module.scss'
import { integrationCards } from 'src/routes/settings/integrations/content/integrationCards'
import { observer } from 'mobx-react-lite'
import { useSetupIntegrations } from 'src/routes/settings/integrations/hooks/useSetupIntegrations'
import configStore from 'store/configStore'
import { AccountIntegrationType } from 'src/generated/graphql'

export const IntegrationsContent = observer(() => {
  const { loading, accountIntegrationType: loadingIntegrationType } =
    useSetupIntegrations()

  return (
    <div className={styles.wrap}>
      {integrationCards
        .filter((card) => {
          if (!configStore.isGoHighLevel) {
            return card.type !== AccountIntegrationType.LeadConnector
          }
          return true
        })
        .map((card) => (
          <IntegrationsCard
            key={card.title}
            {...card}
            loading={loading}
            loadingIntegrationType={loadingIntegrationType}
          />
        ))}
    </div>
  )
})
