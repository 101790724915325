import styles from './styles.module.scss'
import { Currencies, numberString } from 'src/util/functions'
import classNames from 'classnames'
import { isInt } from 'src/util/isInt'

type SubscriptionDetailCardProps = {
  planTitle: string
  creditsIncluded: number
  creditsMigrated?: number | false
  unexpiredCredits?: number
  bonusCredits?: number
  additionalCreditPrice: number
  totalPriceValue: number
  totalCredits?: number | false
  isPerCredit?: boolean
}

export const PaymentDetailCard = ({
  planTitle,
  creditsIncluded,
  creditsMigrated,
  unexpiredCredits,
  additionalCreditPrice,
  totalPriceValue,
  bonusCredits,
  isPerCredit,
  totalCredits,
}: SubscriptionDetailCardProps) => {
  return (
    <div className={styles.detailCard}>
      <span className={'medium'}>Details</span>
      <div className={styles.detailContent}>
        <div className={styles.row}>
          <p className={'par2 gray1'}>Plan</p>
          <p className={'par2'}>{planTitle}</p>
        </div>
        <div className={classNames(styles.row, styles.start)}>
          <p className={'par2 gray1'}>Credits</p>
          <div>
            <p
              className={classNames('par2 text-right', {
                'line-through': !!bonusCredits,
              })}
            >
              {numberString({ val: creditsIncluded })}
            </p>
            {!!bonusCredits && (
              <p className={classNames('par2 text-right')}>
                {numberString({ val: creditsIncluded + bonusCredits })}
              </p>
            )}
          </div>
        </div>
        {!!creditsMigrated && (
          <div className={classNames(styles.row, styles.start)}>
            <p className={'par2 gray1'}>Expired</p>
            <div>
              <p className={classNames('par2')}>
                {numberString({ val: creditsMigrated })}
              </p>
            </div>
          </div>
        )}
        {!!unexpiredCredits && (
          <div className={classNames(styles.row, styles.start)}>
            <p className={'par2 gray1'}>Unexpired</p>
            <div>
              <p className={classNames('par2')}>
                {numberString({ val: unexpiredCredits })}
              </p>
            </div>
          </div>
        )}
        <div className={styles.row}>
          <p className={'par2 gray1'}>
            {isPerCredit ? 'Per' : 'Additional'} credit
          </p>
          <p className={'par2'}>
            {numberString({
              val: additionalCreditPrice,
              maximumFractionDigits: 2,
            })}
            ¢
          </p>
        </div>
      </div>
      <div className={'col8'}>
        {totalCredits && (
          <div className={styles.row}>
            <p className={'par2'}>Total credits</p>
            <p className={'par2 bold'}>
              {numberString({
                val: totalCredits,
                maximumFractionDigits: isInt(totalPriceValue) ? 0 : 2,
              })}
            </p>
          </div>
        )}
        <div className={styles.row}>
          <p className={'par2'}>Total</p>
          <p className={'par2 bold'}>
            {numberString({
              val: totalPriceValue,
              currency: Currencies.usd,
              maximumFractionDigits: isInt(totalPriceValue) ? 0 : 2,
            })}
          </p>
        </div>
        <div className={styles.row} style={{ marginTop: -4 }}>
          <p className={'s4 medium'}>+ applicable taxes</p>
        </div>
      </div>
    </div>
  )
}
