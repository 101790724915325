import { makeAutoObservable } from 'mobx'
import {
  Address,
  ContactBulkOperationRequestInput,
  Maybe,
  OrganizationFragmentFragment,
  Status,
} from 'src/generated/graphql'

class CompanyStore {
  constructor() {
    makeAutoObservable(this)
  }

  organization: OrganizationFragmentFragment | null = null
  showNewReq = true

  migrationStatus: Maybe<Status> | null = Status.Pending

  companyName = ''
  id = 0
  website = ''
  formattedPhoneNumber = ''
  phoneCheckerModal = false
  listsValidateIds: number[] = []
  turnOnAutoRechargeModal:
    | 'phoneChecker'
    | 'trigger'
    | 'callTransfer'
    | 'broadcastResume'
    | 'broadcastCreate'
    | 'callForwarding'
    | null = null
  onTurnOnAutoRechargeModal: (() => void) | null = null
  turnOffPhoneCheckerModal = false
  verifyExisting = true
  activatePhoneCheckrModal = false

  contactsValidateBulk: ContactBulkOperationRequestInput | null = null
  successValidateModal = false
  allowImport = false

  //address
  address: Address | null = null

  get phoneCheckerEnabled() {
    return this.organization?.phoneCheckerEnabled || false
  }
  get complianceUrl() {
    return this.organization?.complianceUrl || ''
  }
  get termsUrl() {
    return this.organization?.termsUrl || ''
  }

  get isContract() {
    return this.organization?.contract
  }

  setOrganization(organization: OrganizationFragmentFragment) {
    this.migrationStatus = organization.migrationStatus || null
    this.organization = organization
    this.allowImport = organization.allowImport
    this.id = organization.id || 0
    this.companyName = organization.companyName || ''
    this.website = organization.website || ''
    this.formattedPhoneNumber = organization.formattedPhoneNumber || ''
    this.address = organization.address || null
  }

  setAllowImport(allowImport: boolean) {
    this.allowImport = allowImport
  }

  setPhoneCheckerModal = ({
    state,
    bulk = null,
    listsValidateIds = [],
  }: {
    state: boolean
    bulk?: ContactBulkOperationRequestInput | null
    listsValidateIds?: number[]
  }) => {
    this.phoneCheckerModal = state
    this.listsValidateIds = listsValidateIds
    this.contactsValidateBulk = bulk
  }

  setTurnOnAutoRechargeModal(
    state: typeof this.turnOnAutoRechargeModal,
    onTurnOnAutoRechargeModal?: typeof this.onTurnOnAutoRechargeModal
  ) {
    this.turnOnAutoRechargeModal = state
    this.onTurnOnAutoRechargeModal = onTurnOnAutoRechargeModal || null
  }
  setTurnOffPhoneCheckerModal(state: boolean) {
    this.turnOffPhoneCheckerModal = state
  }
  setVerifyExisting(state: boolean) {
    this.verifyExisting = state
  }
  setActivatePhoneCheckrModal(state: boolean) {
    this.activatePhoneCheckrModal = state
  }

  setShowNewReq(showNewReq: boolean) {
    this.showNewReq = showNewReq
  }
  setSuccessValidateModal(successValidateModal: boolean) {
    if (successValidateModal) {
      this.contactsValidateBulk = null
      this.phoneCheckerModal = false
      this.listsValidateIds = []
    }
    this.successValidateModal = successValidateModal
  }

  setMigrationStatus(status: typeof this.migrationStatus) {
    this.migrationStatus = status
  }
}

export default new CompanyStore()
