import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { PrimaryCardDropdown } from 'src/routes/settings/billing/components/PrimaryCardDropdown'
import { Modal } from 'components/Modal/Modal'
import React, { useState } from 'react'
import { useCardDropdownOptions } from 'src/routes/settings/billing/components/hooks/useCardDropdownOptions'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { ApolloError } from '@apollo/client'
import { handleCardError } from 'store/settings/billing/functions/handleCardError'

export const AddCardModal = observer(() => {
  const [selectedValue, onSelectValue] = useCardDropdownOptions()
  const [uiLoading, setUiLoading] = useState(false)
  const onClose = () => {
    billingStore.setOpenAddCardModal(false)
  }

  const onNext = async (cardId?: string) => {
    try {
      alertStore.setAlert({
        type: AlertTypeEnum.success,
        title: 'Credit card added',
      })
      onClose()
    } catch (e) {
      if (e instanceof ApolloError) {
        handleCardError(e, (error: string) => {
          billingStore.setUpgradeCardId(cardId || selectedValue || '', error)
        })
      }
    } finally {
      setUiLoading(false)
    }
  }

  const onAdd = () => {
    setUiLoading(true)
    billingStore.setAddActionNewCardCb(onNext)
    billingStore.newCardElement?.click()
  }
  return (
    <Modal
      open={billingStore.openAddCardModal}
      onClose={onClose}
      title={'Add credit card'}
    >
      <div className={'mediumModal'}>
        <PrimaryCardDropdown
          withNewCard={true}
          selectedValue={selectedValue}
          onSelectValue={onSelectValue}
          onFinish={() => setUiLoading(false)}
        />
        <div className={'row12 mt40'}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            loading={uiLoading}
            disabled={!billingStore.readyNewCard}
            onClick={onAdd}
          >
            Add card
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
})
