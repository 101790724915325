import styles from './styles.module.scss'
import { ReactComponent as Logo } from 'assets/images/Logo Horizontal.svg'
import { ReactComponent as Close } from 'assets/icons/16pxNoMask/Close.svg'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Card } from 'components/Card/Card'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  NoCompleteRoutes,
  RoutesEnum,
  UnauthorizedRoutes,
} from 'src/routes/routes'
import { observer } from 'mobx-react-lite'
import { useLogout } from 'components/NavBar/components/Logout'
import { GlobalAlert } from 'components/Alert/GlobalAlert'
import migrationStore from 'store/migration/migrationStore'
import { SizedBox } from 'components/SizedBox'

export const AuthPage = observer(
  ({
    fromReopen,
    isLogoutOnClose,
  }: {
    fromReopen?: boolean
    isLogoutOnClose?: boolean
  }) => {
    const { onLogout } = useLogout()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const isLogin = pathname.includes(UnauthorizedRoutes.login)
    const withoutCloseBtn = isLogin

    const isStep1 = pathname.includes(NoCompleteRoutes.register1)
    const isStep2 = pathname.includes(NoCompleteRoutes.register2)
    const isIndex = pathname === '/'
    const isReopen = pathname.includes(RoutesEnum.reopen) || fromReopen

    const onBack = async () => {
      if (
        isLogoutOnClose ||
        isStep1 ||
        isStep2 ||
        isIndex ||
        migrationStore.fromMigration
      ) {
        onLogout(true)
        return
      }
      navigate(-1)
    }

    return (
      <div className={styles.wrap}>
        <GlobalAlert />
        <div className={styles.header}>
          <div />
          <div>
            <Logo />
          </div>
          <div>
            {withoutCloseBtn ? (
              <SizedBox height={32} />
            ) : (
              <Button
                size={BtnSize.small}
                typeBtn={BtnType.secondaryGray}
                onClick={onBack}
              >
                <Close />
              </Button>
            )}
          </div>
        </div>
        {isReopen ? (
          <div className={styles.reopenWrap}>
            <Outlet />
          </div>
        ) : (
          <Card width={480}>
            <Outlet />
          </Card>
        )}
      </div>
    )
  }
)
