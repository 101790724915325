import { createRoot } from 'react-dom/client'
import { App } from './App'

import { init as initApm } from '@elastic/apm-rum'

if (process.env.ENV !== 'local') {
  const apm = initApm({
    serviceName: 'callloop',
    environment: process.env.ENV,
    serverUrl: 'https://apm.mgmt.callloop.com',
    serviceVersion: '',
  })

  try {
  } catch (error: any) {
    const isPostHogSyntaxError =
      error?.culprit?.includes('https://app.posthog.com/static/recorder') &&
      error?.exception?.message?.includes(
        "Uncaught SyntaxError: Expected ',' or '}' after property value in JSON"
      )

    if (isPostHogSyntaxError) {
      console.error('Ignored SyntaxError from PostHog:', error.message)
    } else {
      apm.captureError(error)
    }
  }

  apm.init()
}

const rootElement = document.getElementById('root')

if (rootElement) {
  const root = createRoot(rootElement)

  root.render(<App />)
}
