// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RQCZ6o7dTxMCYkKMwzoK{background:var(--gradient-1);min-height:100vh;display:flex;padding-top:144px;align-items:flex-start;flex-wrap:wrap;justify-content:center;gap:24px}@media(max-width: 800px){.RQCZ6o7dTxMCYkKMwzoK{padding-top:24px}}`, "",{"version":3,"sources":["webpack://./src/routes/auth/oAuthLogin/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CACA,gBAAA,CACA,YAAA,CACA,iBAAA,CACA,sBAAA,CACA,cAAA,CACA,sBAAA,CACA,QAAA,CACA,yBATF,sBAUI,gBAAA,CAAA","sourcesContent":[".wrap{\n  background: var(--gradient-1);\n  min-height: 100vh;\n  display: flex;\n  padding-top: 144px;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 24px;\n  @media (max-width: 800px) {\n    padding-top: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `RQCZ6o7dTxMCYkKMwzoK`
};
export default ___CSS_LOADER_EXPORT___;
