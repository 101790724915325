import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { CanceledPage } from 'src/routes/canceled/cancel/CanceledPage'
import { ReopenPage } from 'src/routes/canceled/reopen/ReopenPage'
import { RoutesEnum } from 'src/routes/routes'

export const CanceledRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthPage />}>
        <Route index element={<CanceledPage />} />
        <Route path={RoutesEnum.reopen} element={<ReopenPage />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Route>
    </Routes>
  )
}
