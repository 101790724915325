import styles from './styles.module.scss'
import billingStore from 'store/settings/billing/billingStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { Currencies, numberString } from 'src/util/functions'
import { SizedBox } from 'components/SizedBox'
import { observer } from 'mobx-react-lite'
import {
  CardDropdownProps,
  PrimaryCardDropdown,
} from 'src/routes/settings/billing/components/PrimaryCardDropdown'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { useSwitchPlanMutation } from 'src/generated/graphql'
import { AutoRechargeCard } from 'components/AutoRecahrge/AutoRechargeCard'
import { useState } from 'react'
import alertStore from 'store/alertStore'
import { useSetPaymentsMethods } from 'src/routes/settings/billing/hooks/useSetPaymentsMethods'
import { PlanPeriodsEnum } from 'src/enums/planPeriodsEnum'
import { SubscriptionDetailCard } from 'src/Modals/UpgradePlanModal/components/SubscriptionDetailCard'
import { ApolloError } from '@apollo/client'
import { handleCardError } from 'store/settings/billing/functions/handleCardError'

export const Step2Upgrade = observer(
  ({ selectedValue, onSelectValue }: CardDropdownProps) => {
    useSetPaymentsMethods()

    const [upgradePlan, { loading }] = useSwitchPlanMutation()
    const [uiLoading, setUiLoading] = useState(false)

    const handleUpgrade = async (cardId?: string) => {
      if (!upgradePlanStore.planName) return
      try {
        const res = await upgradePlan({
          variables: {
            price: upgradePlanStore.planName,
            paymentMethodId: cardId || selectedValue,
          },
        })
        if (res.data?.switchPlan?.subscription) {
          billingStore.setClSubscription(res.data.switchPlan.subscription)
          res.data?.switchPlan?.balances &&
            billingStore.setBalances(res.data?.switchPlan?.balances)
          upgradePlanStore.onConfirm()
        }
      } catch (e) {
        if (e instanceof ApolloError) {
          handleCardError(e, (error: string) => {
            billingStore.setUpgradeCardId(cardId || selectedValue || '', error)
          })
        }
      } finally {
        setUiLoading(false)
      }
    }

    const onAddCard = () => {
      billingStore.setAddActionNewCardCb(handleUpgrade)
      billingStore.newCardElement?.click()
    }
    const onContinue = () => {
      setUiLoading(true)
      if (billingStore.showNewCard) {
        billingStore.setAddActionNewCardCb(() => {
          upgradePlanStore.setActiveModalStep(2)
          setUiLoading(false)
        })
        billingStore.newCardElement?.click()
      } else {
        upgradePlanStore.setActiveModalStep(2)
        setUiLoading(false)
      }
    }
    const onConfirm = () => {
      setUiLoading(true)
      if (billingStore.showNewCard) {
        onAddCard()
      } else {
        handleUpgrade()
      }
    }
    const onBack = () => {
      upgradePlanStore.setActiveModalStep(0)
    }
    return (
      <>
        <div className={styles.wrap}>
          <div>
            <span className={'s2 medium gray1 mb8'}>Billing</span>
            <div className={styles.wrapInputs}>
              {!upgradePlanStore.isPayAsYouGo &&
                upgradePlanStore.modalSimplePriceMonthly?.priceValue && (
                  <InputCheckbox
                    label={'Monthly'}
                    checked={upgradePlanStore.modalActivePeriod === 'Monthly'}
                    onChecked={() =>
                      upgradePlanStore.setActiveModalPeriod(
                        PlanPeriodsEnum.Monthly
                      )
                    }
                    rightIcon={
                      <div className={styles.rightInputContent}>
                        <span className={'nowrap'}>
                          <span className={'bold'}>
                            {numberString({
                              val:
                                upgradePlanStore.modalSimplePriceMonthly
                                  ?.priceValue || 0,
                              currency: Currencies.usd,
                              maximumFractionDigits: 0,
                            })}
                            &nbsp;
                          </span>
                          <span>/ month</span>
                        </span>
                      </div>
                    }
                    radio
                  />
                )}
              {upgradePlanStore.modalSimplePriceAnnual?.priceValue && (
                <InputCheckbox
                  label={'Annually'}
                  checked={
                    upgradePlanStore.modalActivePeriod ===
                      PlanPeriodsEnum.Annual || upgradePlanStore.isPayAsYouGo
                  }
                  onChecked={() =>
                    !upgradePlanStore.isPayAsYouGo &&
                    upgradePlanStore.setActiveModalPeriod(
                      PlanPeriodsEnum.Annual
                    )
                  }
                  rightIcon={
                    <div className={styles.rightInputContent}>
                      {!!upgradePlanStore.modalSavedMoney && (
                        <div className={styles.save}>
                          <span className={'s2 success1'}>
                            {upgradePlanStore.isPayAsYouGo
                              ? '50% off'
                              : `Save ${numberString({
                                  val: upgradePlanStore.modalSavedMoney,
                                  currency: Currencies.usd,
                                  maximumFractionDigits: 0,
                                })}`}
                          </span>
                        </div>
                      )}
                      <span className={'nowrap'}>
                        <span className={'bold'}>
                          {numberString({
                            val:
                              (upgradePlanStore.modalSimplePriceAnnual
                                ?.priceValue || 0) / 12,
                            currency: Currencies.usd,
                            maximumFractionDigits: 0,
                          })}
                          &nbsp;
                        </span>
                        <span>/ month</span>
                      </span>
                    </div>
                  }
                  radio
                />
              )}
            </div>
            <SizedBox height={40} />
            <PrimaryCardDropdown
              selectedValue={selectedValue}
              onSelectValue={onSelectValue}
              onFinish={() => setUiLoading(false)}
            />
            <AutoRechargeCard
              planName={upgradePlanStore.planName || ''}
              fromUpgrade
            />
          </div>
          <SubscriptionDetailCard />
          <div className={styles.actions}>
            {upgradePlanStore.isDowngradeSubscription ? (
              <Button
                typeBtn={BtnType.primary}
                size={BtnSize.medium}
                onClick={onContinue}
                disabled={
                  billingStore.showNewCard
                    ? !billingStore.readyNewCard
                    : !selectedValue ||
                      selectedValue === billingStore.upgradeCardId
                }
                loading={uiLoading}
              >
                Continue
              </Button>
            ) : (
              <Button
                typeBtn={BtnType.primary}
                size={BtnSize.medium}
                onClick={onConfirm}
                disabled={
                  !upgradePlanStore.planName || billingStore.showNewCard
                    ? !billingStore.readyNewCard
                    : !selectedValue ||
                      selectedValue === billingStore.upgradeCardId
                }
                loading={loading || uiLoading}
              >
                {`Upgrade to ${upgradePlanStore.planTitle}`}
              </Button>
            )}
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={onBack}
            >
              Back
            </Button>

            <div />
          </div>
        </div>
      </>
    )
  }
)
