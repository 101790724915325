import { DowngradeReasonType } from 'src/generated/graphql'
import {
  FeedbackContent,
  IFeedbackContentProps,
} from 'components/Feedbackontent/FeedbackContent'

const cancellationReasonTypeAnswers: Map<DowngradeReasonType, string> = new Map(
  [
    [DowngradeReasonType.WrongPlan, 'I was on the wrong plan'],
    [
      DowngradeReasonType.BudgetIssue,
      'I can no longer afford it / budget reasons',
    ],
    [DowngradeReasonType.SeasonalBusiness, 'My business is seasonal'],
    [DowngradeReasonType.UnusedCredits, 'I didn’t use the credits'],
    [DowngradeReasonType.TooManyCredits, 'Too many credits'],
    [DowngradeReasonType.Other, 'Other'],
  ]
)

type IDowngradeFeedbackContentProps = Omit<
  IFeedbackContentProps,
  'selectedAnswer' | 'setSelectedAnswer' | 'answers' | 'title'
> & {
  selectedAnswer: DowngradeReasonType | null
  setSelectedAnswer: (id: DowngradeReasonType) => void
}

export const DowngradeFeedbackContent = ({
  selectedAnswer,
  setSelectedAnswer,
  ...props
}: IDowngradeFeedbackContentProps) => {
  const handleSelectedAnswer = (id: string) => {
    setSelectedAnswer(id as DowngradeReasonType)
  }

  const map = new Map(cancellationReasonTypeAnswers)
  const answers = Array.from(map.entries())

  return (
    <FeedbackContent
      {...props}
      selectedAnswer={selectedAnswer}
      setSelectedAnswer={handleSelectedAnswer}
      answers={answers}
      title={"We'd like to know why you're downgrading:"}
    />
  )
}
