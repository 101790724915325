import { AccountIntegrationType } from 'src/generated/graphql'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import integrationsStore from 'store/settings/integrationsStore'
import { IAccountIntegrationType } from 'src/routes/settings/integrations/content/integrationCards'
import customIntegrationModalStore from 'src/routes/settings/integrations/modal/CustomIntegrationModal/store/customIntegrationModalStore'

export const ConnectIntegrationBtn = ({
  type,
  authUrl,
  loadingIntegrationType,
  loading,
  text = 'Connect',
  refetchFields,
  invalidated,
}: {
  type: IAccountIntegrationType
  loadingIntegrationType?: IAccountIntegrationType | null
  authUrl?: string | null
  loading?: boolean
  text?: string
  refetchFields?: () => Promise<any>
  invalidated?: boolean
}) => {
  const onConnect = () => {
    if (type === AccountIntegrationType.Custom) {
      customIntegrationModalStore.setOpenModal(true)
      return
    }
    integrationsStore.setConnectIntegrationModal(
      type as AccountIntegrationType,
      refetchFields
    )
  }
  return authUrl ? (
    <a href={authUrl || ''} target={'_blank'} rel="noreferrer">
      <Button
        size={BtnSize.small}
        typeBtn={BtnType.primary}
        disabled={!authUrl}
        fullWidth
        loading={loadingIntegrationType === type && loading}
      >
        {invalidated ? 'Reconnect' : text}
      </Button>
    </a>
  ) : (
    <Button
      size={BtnSize.small}
      typeBtn={BtnType.primary}
      onClick={onConnect}
      fullWidth
    >
      {text}
    </Button>
  )
}
