import styles from './styles.module.scss'
import billingStore from 'store/settings/billing/billingStore'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import {
  DowngradeReasonType,
  useSwitchPlanMutation,
} from 'src/generated/graphql'
import { CardDropdownProps } from 'src/routes/settings/billing/components/PrimaryCardDropdown'
import { SubscriptionDetailCard } from 'src/Modals/UpgradePlanModal/components/SubscriptionDetailCard'
import { DowngradeFeedbackContent } from 'components/Feedbackontent/DowngradeFeedbackContent'

export const Step3Downgrade = observer(
  ({ selectedValue }: CardDropdownProps) => {
    const [downgradeReason, setDowngradeReason] =
      useState<DowngradeReasonType | null>(null)
    const [comment, setComment] = useState('')
    const [upgradePlan, { loading }] = useSwitchPlanMutation()

    const handleUpgrade = async () => {
      if (!upgradePlanStore.planName) return
      try {
        if (!selectedValue) {
          return
        }
        const res = await upgradePlan({
          variables: {
            price: upgradePlanStore.planName,
            paymentMethodId: selectedValue,
            userInput: {
              comment,
              downgradeReason,
            },
          },
        })
        if (res.data?.switchPlan?.subscription) {
          billingStore.setClSubscription(res.data.switchPlan.subscription)
          res.data?.switchPlan?.balances &&
            billingStore.setBalances(res.data?.switchPlan?.balances)
          upgradePlanStore.onConfirm()
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onBack = () => {
      upgradePlanStore.setActiveModalStep(0)
    }
    return (
      <>
        <div className={styles.wrap}>
          <DowngradeFeedbackContent
            selectedAnswer={downgradeReason}
            setSelectedAnswer={setDowngradeReason}
            comment={comment}
            setComment={setComment}
          />
          <SubscriptionDetailCard />
          <div className={styles.actions}>
            <Button
              typeBtn={BtnType.primary}
              size={BtnSize.medium}
              onClick={handleUpgrade}
              disabled={!downgradeReason}
              loading={loading}
            >
              Downgrade to {upgradePlanStore.planTitle}
            </Button>
            <Button
              typeBtn={BtnType.secondaryGray}
              size={BtnSize.medium}
              onClick={onBack}
            >
              Back
            </Button>
            <div />
          </div>
        </div>
      </>
    )
  }
)
