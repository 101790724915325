import { Alert } from 'components/Alert/Alert'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { BalanceFallsOptions } from 'store/settings/billing/hellpers'
import billingStore from 'store/settings/billing/billingStore'
import { IOption } from 'src/types/IOption'
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import {
  AutoRechargeThreshold,
  useSetupAutoRechargeMutation,
} from 'src/generated/graphql'
import alertStore from 'store/alertStore'
import { useSetCreditPrices } from 'src/routes/settings/billing/hooks/useSetCreditPrices'
import { observer } from 'mobx-react-lite'
import { iCreditPriceToOption } from 'store/settings/billing/functions/iCreditPriceToOption'
import classNames from 'classnames'

export const ConfigureAutoRecharge = observer(
  ({ onClose }: { onClose: () => void }) => {
    const planName = billingStore.clSubscription?.price?.planName || ''
    useSetCreditPrices(planName)
    const [fallsOption, setFallsOption] = useState(
      billingStore.autoRechargeThresholdOption
    )

    const creditPriceOptions: IOption[] = billingStore.creditPrices.map(
      (price) => iCreditPriceToOption(price)
    )

    const activeCreditPriceOption =
      billingStore.activeCreditPrice &&
      iCreditPriceToOption(billingStore.activeCreditPrice)

    const [selectedCreditPriceOption, setSelectedCreditPriceOption] = useState(
      activeCreditPriceOption
    )

    useEffect(() => {
      if (activeCreditPriceOption && !selectedCreditPriceOption) {
        setSelectedCreditPriceOption(activeCreditPriceOption)
      }
    }, [activeCreditPriceOption])

    const [setupAutoRecharge, { loading }] = useSetupAutoRechargeMutation()

    const onEnableAutoRecharge = async () => {
      try {
        const res = await setupAutoRecharge({
          variables: {
            options: {
              enable: true,
              creditsAmount: selectedCreditPriceOption?.value,
              threshold: fallsOption.value as AutoRechargeThreshold,
            },
          },
        })
        if (res.data?.setupAutoRecharge) {
          billingStore.setOrganizationBillingInfo(res.data?.setupAutoRecharge)
        }
        onClose()
        alertStore.setAlert({
          type: AlertTypeEnum.success,
          text: 'Auto-Recharge is enabled',
        })
      } catch (e) {
        console.error(e)
      }
    }

    return (
      <div className={styles.wrap}>
        <Alert
          title={'Guarantee Delivery'}
          text={
            'Automatically add funds to your account when it falls below a certain threshold to ensure uninterrupted messaging.'
          }
          type={AlertTypeEnum.notify2}
          className={'mb32'}
        />
        <div className={'col20'}>
          <div className={styles.wrapOptions}>
            <Dropdown
              labelInput={'If balance falls bellow'}
              options={BalanceFallsOptions}
              selectedOption={fallsOption}
              onSelect={setFallsOption}
            />
            <Dropdown
              labelInput={'Recharge my account'}
              options={creditPriceOptions}
              selectedOption={selectedCreditPriceOption}
              onSelect={setSelectedCreditPriceOption}
            />
            <span className={classNames('s3 gray1', styles.taxes)}>
              + applicable taxes
            </span>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onEnableAutoRecharge}
            loading={loading}
          >
            Enable Auto-Recharge
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }
)
