import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { UnauthorizedRoutes } from 'src/routes/routes'
import { observer } from 'mobx-react-lite'
import { LegacyRef, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import auth from 'store/auth'
import { SizedBox } from 'components/SizedBox'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { AppGoogleLogin } from 'components/GoogleLogin/AppGoogleLogin'
import { HorizontalDivider } from 'components/HorizontalDevider/HorizontalDivider'
import { InputText } from 'components/Input/InputText/InputText'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { appLinks } from 'src/util/links'
import { FooterText } from 'src/routes/auth/components/FooterText/FooterText'

export const Login = observer(() => {
  const recaptchaRef = useRef<ReCAPTCHA>()

  const params = useSearchParams()
  params[0] && auth.setLoginSearchParams(params[0])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [typePassword, setTypePassword] = useState('password')
  const [loading, setLoading] = useState(false)
  const [isRecaptchaSuccess, setRecaptchaSuccess] = useState(false)
  const navigate = useNavigate()

  const onBanned = () => {
    navigate(UnauthorizedRoutes.ban)
  }
  const onLogin = async () => {
    try {
      setLoading(true)
      let isRecaptcha = isRecaptchaSuccess
      if (!isRecaptcha) {
        isRecaptcha = !!(await recaptchaRef.current?.executeAsync())
      }

      if (isRecaptcha) {
        setRecaptchaSuccess(true)
        auth.setAuthError('')

        await auth.onLogin(email, password, onBanned)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    auth.setAuthError('')
  }, [])

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef as LegacyRef<ReCAPTCHA>}
        sitekey={process.env.RECAPTCHA_KEY || ''}
        size={'invisible'}
        hidden
      />
      <h2>Log in</h2>
      <p className={'par2 gray1 mt8'}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Don't have an account?&nbsp;
        <Link to={UnauthorizedRoutes.signup}>Sign up</Link>
      </p>
      <SizedBox height={32} />
      {auth.authError &&
        (auth.authError.includes(
          'Please enter your business email address.'
        ) ? (
          <>
            <Alert text={auth.authError} type={AlertTypeEnum.error} />
            <SizedBox height={20} />
          </>
        ) : (
          <>
            <Alert
              title={'Incorrect email or password'}
              text={
                'The email or password you entered did not match our records. Please double-check and try again.'
              }
              type={AlertTypeEnum.error}
            />
            <SizedBox height={20} />
          </>
        ))}

      <AppGoogleLogin />
      <HorizontalDivider color={'PrimaryColorGray2'} />
      <SizedBox height={20} />
      <InputText
        id={'email'}
        label={'Email address'}
        placeholder={'email@address.com'}
        value={email}
        onChange={(e) => setEmail(e.target.value.trim())}
        onClickRightIcon={() => setEmail('')}
        focusOnMount
      />
      <SizedBox height={16} />
      <InputText
        // hideRightContent={!isWrongPassword}
        password
        rightLabelContent={
          <Link to={UnauthorizedRoutes.forgot} tabIndex={-1}>
            Forgot password?
          </Link>
        }
        id={'password'}
        label={'Password'}
        placeholder={'Enter your password'}
        value={password}
        type={typePassword}
        onChange={(e) => setPassword(e.target.value.trim())}
        onClickRightIcon={() =>
          setTypePassword(typePassword === 'password' ? 'text' : 'password')
        }
      />
      <SizedBox height={40} />
      <Button
        size={BtnSize.large}
        typeBtn={BtnType.primary}
        onClick={onLogin}
        loading={loading}
        // disabled={!isFill}
      >
        Log in
      </Button>
      <SizedBox height={16} />
      <FooterText>
        This site is protected by reCAPTCHA and the Google <br />{' '}
        <a href={appLinks.googlePrivacy} target={'_blank'} rel="noreferrer">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          href={appLinks.googleTermsService}
          target={'_blank'}
          rel="noreferrer"
        >
          Terms of Service
        </a>{' '}
        apply.
      </FooterText>
    </>
  )
})
