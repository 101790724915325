import { makeAutoObservable } from 'mobx'
import {
  BalanceFallsOptions,
  BalanceFallsOptionsMap,
  payGoLite,
  simplePriceToTitle,
} from 'store/settings/billing/hellpers'
import { TableStore } from 'components/NewTable/store/TableStore'
import user from 'store/user/user'
import { cardToOption } from 'src/util/card'
import { IInvoice } from 'src/routes/settings/billing/Invoices/types'
import { invoicesColumns } from 'components/NewTable/columns/invoicesColumns'
import {
  AutoRechargeThreshold,
  Balances,
  BillingInvoice,
  ClStatus,
  ClSubscription,
  Direction,
  GetCreditPriceSelectionForPlansQuery,
  Maybe,
  OrgBillingInfo,
  PaymentMethod,
  SimplePrice,
} from 'src/generated/graphql'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { AppSymbols } from 'src/util/symbols'
import { clInvoiceToIInvoice } from 'src/routes/settings/billing/functions/clInvoiceToIInvoice'
import { Currencies, numberString } from 'src/util/functions'
import { IOption } from 'src/types/IOption'
import { ICreditPrice } from 'store/settings/billing/type'
import companyStore from 'store/settings/company/companyStore'

class BillingStore {
  invoiceTableStore: TableStore<IInvoice>
  user: typeof user

  constructor() {
    makeAutoObservable(this)

    this.invoiceTableStore = new TableStore({
      orderBy: 'date',
      tableName: 'InvoicesTable',
      columns: invoicesColumns,
      rowsPerPage: 10,
      order: Direction.Desc,
    })
    this.user = user
    this.disabledHideNotEnoughCreditsTimestamp = Number(
      localStorage.getItem('disabledHideNotEnoughCreditsTimestamp')
    )
  }

  loadingPaymentMethods = true

  _autoRecharge = false

  get autoRecharge() {
    return this._autoRecharge
  }
  // phoneChecker = false
  autoRechargeCreditsAmount = 500
  autoRechargeThreshold: AutoRechargeThreshold =
    AutoRechargeThreshold.Credits100

  prices: SimplePrice[] = []

  stripeCustomerId = ''
  cancelAccountAt: Date | null = null

  clSubscription: ClSubscription | null = null
  expandPaymentCard = false

  balances: Balances | null = null

  openByCreditsModal = false
  openCancelSubscriptionModal = false
  openPauseSubscriptionModal = false
  openSuccessPauseSubscriptionModal = false
  openSuccessCanceledSubscriptionModal = false
  openContinueCancelSubscriptionModal = false
  openBuySubscriptionModal = false
  successBuyCredits = 0
  isPausedSubscription = false

  paymentsMethodsMap: Map<string, PaymentMethod> = new Map<
    string,
    PaymentMethod
  >()

  downgradedPrice: SimplePrice | null = null

  newCardElement: HTMLButtonElement | null = null
  showNewCard = false
  showNewCardUpdate = false
  readyNewCard = false
  addActionNewCardCb: ((cardId: string, tokenId?: string) => void) | null = null

  limitModalFor = ''

  invoicesMap: Map<string, IInvoice> = new Map()

  openDisabledAutoRechargeModal = false

  primaryPaymentMethodId = ''
  backupPaymentMethodId = ''

  disableHideAutoRechargeFor = ''
  buyCreditsInfo = ''
  upgradeCardId = ''
  upgradeCardError = ''
  upgradedCardId = ''

  disabledHideNotEnoughCreditsTimestamp = 0

  openUpdateCardModal = false
  openAddCardModal = false

  creditPricesMap: Map<string, Array<ICreditPrice>> = new Map<
    string,
    Array<ICreditPrice>
  >()

  get isCustomPlan() {
    return this.clSubscription?.price?.planLine === 'Custom'
  }

  get creditPrices() {
    if (!this.clSubscription?.price?.planName) {
      return []
    }
    return this.creditPricesMap.get(this.clSubscription?.price?.planName) || []
  }

  get activeCreditPrice() {
    return this.creditPrices.find(
      (price) => price.creditsAmount === this.autoRechargeCreditsAmount
    )
  }

  get isPayGoLite() {
    return this.clSubscription?.price?.planTitle === payGoLite
  }

  get isNotEnoughCreditsAlert() {
    if (!this.balances) {
      return false
    }
    if (this.disabledHideNotEnoughCreditsTimestamp) {
      const isHide =
        Date.now() - this.disabledHideNotEnoughCreditsTimestamp <
        1000 * 60 * 60 * 24
      if (isHide) {
        return false
      } else {
        return this.currentCredits <= 0
      }
    } else {
      return this.currentCredits <= 0
    }
  }

  get invoices() {
    return Array.from(this.invoicesMap.values())
  }

  get autoRechargeThresholdOption(): IOption<AutoRechargeThreshold> {
    return (
      BalanceFallsOptionsMap.get(this.autoRechargeThreshold) ||
      BalanceFallsOptions[0]
    )
  }

  get activeSubscription() {
    let status = this.clSubscription?.status as ClStatus
    if (window.location.href.includes('localhost') && !status) {
      const localStatus = localStorage.getItem(
        'clSubscriptionStatus'
      ) as ClStatus

      if (localStatus) {
        status = JSON.parse(localStatus)
      }
    }
    return (
      status === ClStatus.Active ||
      status === ClStatus.Trialing ||
      status === ClStatus.PastDue
    )
  }

  get canceledSubscription() {
    return this.clSubscription?.status === ClStatus.Canceled
  }

  get isCancelSubscription() {
    return this.clSubscription?.cancelAt
  }

  get isMigratedSubscription() {
    return this.clSubscription?.price?.planLine === 'Migrated'
  }

  get clSubscriptionTitle() {
    if (!this.clSubscription) {
      return ''
    }
    if (this.clSubscription.status === ClStatus.Trialing) {
      return 'Free trial'
    }
    if (this.isMigratedSubscription) {
      return this.clSubscription.price?.planTitle
    }
    return simplePriceToTitle(this.clSubscription?.price)
  }

  get nextClSubscriptionTitle() {
    return simplePriceToTitle(this.clSubscription?.nextPlan?.price) || ''
  }

  get clAdditionalCreditPrice() {
    return Number(
      ((this.clSubscription?.price?.additionalCreditPrice || 0) * 100).toFixed(
        2
      )
    )
  }

  get clSubscriptionRenews() {
    if (this.clSubscription) {
      return dayjs(this.clSubscription?.renews).format(DayjsFormats.date)
    }
    return ''
  }

  get downgradeDate() {
    if (this.clSubscription?.nextPlan?.startDate) {
      return dayjs(this.clSubscription?.nextPlan?.startDate).format(
        DayjsFormats.date
      )
    }
    return ''
  }

  get planDescription() {
    if (this.clSubscription) {
      return `${this.clSubscription.price?.creditsIncluded} credits ${
        AppSymbols.point
      } ${numberString({
        val: this.clSubscription.price?.priceValue || 0,
        currency: Currencies.usd,
        maximumFractionDigits: 0,
      })}/${
        this.clSubscription.price?.period === 'Annual' ? 'year' : 'month'
      } ${AppSymbols.point} ${numberString({
        val: this.clSubscription.price?.additionalCreditPrice || 0,
        currency: Currencies.usd,
        maximumFractionDigits: 3,
      })}/additional credit`
    }
    return ''
  }

  get clSubscriptionDescription() {
    if (this.isTrial) {
      return `${this.leftDaysTriallingString} ${AppSymbols.point} ${this.endTrialDateString}`
    }
    if (this.clSubscription?.nextPlan) {
      return `${this.planDescription} ${AppSymbols.point} ${
        this.isPayGoLite ? 'Upgrades' : 'Downgrades'
      } on  ${this.downgradeDate}`
    }
    if (this.clSubscription) {
      return `${this.planDescription} ${AppSymbols.point} Renews ${this.clSubscriptionRenews}`
    }
    return ''
  }

  get subscriptionCredits() {
    if (!this.balances) return 0
    if (this.isTrial) return 25
    return this.clSubscription?.price?.creditsIncluded || 0
  }

  get rolloverCredits() {
    if (!this.balances) return 0
    return this.balances.rollover || 0
  }

  get purchasedCredits() {
    if (!this.balances) return 0
    return this.balances.purchased || 0
  }

  get planCredits() {
    if (!this.balances) return 0
    return this.balances.plan || 0
  }

  get currentCredits() {
    if (!this.balances) return 0
    return (
      this.planCredits +
      (this.balances.purchased || 0) +
      (this.balances.rollover || 0)
    )
  }

  get isTrial() {
    if (this.clSubscription) {
      const status = this.clSubscription.status
      if (status) {
        return status === ClStatus.Trialing
      }
    }
    return false
  }

  get isPayGo() {
    if (this.clSubscription) {
      return this.clSubscription.price?.planName === 'PayAsYouGo'
    }
    return false
  }

  get endTrialDateString() {
    if (this.isTrial) {
      return `Ends on ${dayjs(this.clSubscription?.renews).format(
        DayjsFormats.date
      )}`
    }
  }

  get cancelAtString() {
    if (this.clSubscription?.cancelAt) {
      return dayjs(this.clSubscription?.cancelAt).format(DayjsFormats.date)
    }
    return ''
  }

  get leftDaysTrialling() {
    if (this.isTrial && this.clSubscription?.renews) {
      return Math.ceil(
        (dayjs(this.clSubscription?.renews).valueOf() - dayjs().valueOf()) /
          1000 /
          60 /
          60 /
          24
      )
    }
    return 0
  }

  get leftDaysTriallingString() {
    if (this.isTrial && this.clSubscription?.renews) {
      return this.leftDaysTrialling + ' days left'
    }
    return ''
  }

  get paymentsMethods() {
    return Array.from(this.paymentsMethodsMap.values())
  }

  get primaryCard() {
    return this.paymentsMethods.find((card) => card.type === 'Primary')
  }

  get allCredits() {
    if (this.isTrial) return 25
    return this.clSubscription?.price?.creditsIncluded || 0
  }

  get cardsOptions(): IOption[] {
    return this.paymentsMethods.map(cardToOption)
  }

  get primaryCardOption(): IOption | undefined {
    if (this.primaryCard) {
      return cardToOption(this.primaryCard)
    }
    return this.cardsOptions[0] || undefined
  }

  get showLimitModal() {
    return !!this.limitModalFor
  }

  setUpgradeCardId = (id: string, error: string) => {
    this.upgradeCardId = id
    this.upgradeCardError = error
  }

  setInvoices(invoices: Array<Maybe<BillingInvoice>>) {
    this.invoicesMap.clear()
    const iInvoices: IInvoice[] = []
    invoices.forEach((invoice) => {
      if (invoice) {
        iInvoices.push(clInvoiceToIInvoice(invoice))
      }
    })
    this.invoicesMap = new Map<string, IInvoice>(
      iInvoices.map((invoice) => [invoice.id, invoice])
    )
  }

  setPaymentMethods(paymentMethods: Array<Maybe<PaymentMethod>>) {
    const filtered: PaymentMethod[] = []
    paymentMethods.forEach((method) => {
      if (method) {
        filtered.push(method)
      }
    })
    this.paymentsMethodsMap = new Map<string, PaymentMethod>(
      filtered.map((paymentMethod) => [paymentMethod.id || '', paymentMethod])
    )
  }

  setOpenBuyCreditsModal = (state: boolean, info = '') => {
    this.buyCreditsInfo = info
    if (state) {
      this.expandPaymentCard = false
    }
    this.clearCardElement()
    this.openByCreditsModal = state
    this.disableHideAutoRechargeFor = ''
  }

  clearCardElement() {
    this.readyNewCard = false
    this.showNewCard = false
    this.showNewCardUpdate = false
    this.upgradedCardId = ''
    this.upgradeCardId = ''
    this.upgradeCardError = ''
    this.addActionNewCardCb = null
  }

  setSuccessBuyCredits(credits: number) {
    this.successBuyCredits = credits
  }

  setOpenCancelSubscriptionModal(state: boolean) {
    if (state && companyStore.isContract) {
      return
    }
    this.openCancelSubscriptionModal = state
  }

  setOpenPauseSubscriptionModal(state: boolean) {
    this.clearCardElement()
    this.openPauseSubscriptionModal = state
  }

  setOpenSuccessPauseSubscriptionModal(state: boolean) {
    this.openSuccessPauseSubscriptionModal = state
  }

  setOpenSuccessCanceledSubscriptionModal(state: boolean) {
    this.openSuccessCanceledSubscriptionModal = state
  }

  setIsPausedSubscription(state: boolean) {
    this.isPausedSubscription = state
  }

  setOpenContinueCancelSubscriptionModal = (state: boolean) => {
    this.openContinueCancelSubscriptionModal = state
  }

  setOpenBuySubscriptionModal(state: boolean) {
    this.openBuySubscriptionModal = state
  }

  setClSubscription(subscription: ClSubscription) {
    if (window.location.href.includes('localhost')) {
      localStorage.setItem(
        'clSubscriptionStatus',
        JSON.stringify(subscription.status)
      )
    }

    this.clSubscription = subscription
  }

  setNewCardElement(element: HTMLButtonElement | null) {
    this.newCardElement = element
  }

  setExpandPaymentCard(state: boolean) {
    this.expandPaymentCard = state
  }

  setShowNewCard(state: boolean) {
    this.showNewCard = state
  }

  setReadyNewCard(state: boolean) {
    this.readyNewCard = state
  }

  setShowNewCardUpdate(state: boolean) {
    this.showNewCardUpdate = state
  }

  setAddActionNewCardCb(cb: ((cardId: string) => void) | null) {
    this.addActionNewCardCb = cb
  }

  setBalances(balances: Balances) {
    this.balances = balances
  }

  setLimitModalFor(val: string) {
    this.limitModalFor = val
  }

  setCancelAccountAt(val: Date | null = null) {
    this.cancelAccountAt = val
  }

  setOrganizationBillingInfo = (organizationBillingInfo: OrgBillingInfo) => {
    this.autoRechargeCreditsAmount =
      organizationBillingInfo.autoRechargeCreditsAmount || 500
    this.autoRechargeThreshold =
      organizationBillingInfo.autoRechargeThreshold ||
      AutoRechargeThreshold.Credits100
    this._autoRecharge = !!organizationBillingInfo.autoRecharge
    if (organizationBillingInfo?.paymentMethods) {
      this.setPaymentMethods(organizationBillingInfo?.paymentMethods)
    }
    if (organizationBillingInfo.cancelAccountAt) {
      this.cancelAccountAt = organizationBillingInfo.cancelAccountAt
    }
    if (organizationBillingInfo?.stripeCustomerId) {
      this.stripeCustomerId = organizationBillingInfo?.stripeCustomerId
    }
    if (organizationBillingInfo?.primaryPaymentMethod) {
      this.primaryPaymentMethodId =
        organizationBillingInfo?.primaryPaymentMethod
    }
    if (organizationBillingInfo?.backupPaymentMethod) {
      this.backupPaymentMethodId = organizationBillingInfo?.backupPaymentMethod
    }
  }

  setOpenDisabledAutoRechargeModal(state: boolean) {
    this.openDisabledAutoRechargeModal = state
  }

  setDisableHideAutoRechargeFor(value: 'fromUpgrade' | 'fromBuyCredits' | '') {
    this.disableHideAutoRechargeFor = value
  }

  onCloseNotEnoughCreditsAlert() {
    this.setDisabledHideNotEnoughCreditsTimestamp(Date.now())
  }

  setDisabledHideNotEnoughCreditsTimestamp(timestamp: number) {
    this.disabledHideNotEnoughCreditsTimestamp = timestamp
    localStorage.setItem(
      'disabledHideNotEnoughCreditsTimestamp',
      JSON.stringify(this.disabledHideNotEnoughCreditsTimestamp)
    )
  }

  setOpenUpdateCardModal = (value: boolean) => {
    this.openUpdateCardModal = value
  }

  setOpenAddCardModal = (value: boolean) => {
    this.openAddCardModal = value
  }

  setCreditPriceSelection = (data: GetCreditPriceSelectionForPlansQuery) => {
    data.getCreditPriceSelectionForPlans?.forEach((plan) => {
      if (plan) {
        const allowedPrices: ICreditPrice[] = []
        plan?.allowedPrices?.forEach((price) => {
          if (price) {
            allowedPrices.push({ ...price, allowed: true })
          }
        })
        const upgradePrices: ICreditPrice[] = []
        plan?.upgradePrices?.forEach((price) => {
          if (price) {
            upgradePrices.push({ ...price, allowed: false })
          }
        })
        if (plan.planName) {
          this.creditPricesMap.set(plan.planName, [
            ...allowedPrices,
            ...upgradePrices,
          ])
        }
      }
    })
  }

  setPaymentsMethodLoading = (loading: boolean) => {
    this.loadingPaymentMethods = loading
  }
}

export default new BillingStore()
