import { SizedBox } from 'components/SizedBox'
import { InputText } from 'components/Input/InputText/InputText'
import { useState } from 'react'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { VerifyContent } from 'src/Modals/VerifyModal/VerifyContent'
import accountStore from 'store/settings/accountStore'
import axios from 'axios'
import { ForgotNewPassword } from 'src/routes/auth/components/ForgotNewPassword'
import { appLinks } from 'src/util/links'
import { FooterText } from 'src/routes/auth/components/FooterText/FooterText'

export const ForgotPassword = () => {
  const [isError, setIsError] = useState(false)
  const [email, setEmail] = useState('')
  const trimEmail = email.trim()
  const [code, setCode] = useState<number | string>(0)
  const [verifyContent, setVerifyContent] = useState(false)
  const [newPasswordContent, setNewPasswordContent] = useState(false)
  const onVerify = async (verificationCode: string) => {
    setCode(verificationCode)
    await axios.post('auth/password/restore/verify', {
      verificationCode,
      email: trimEmail,
    })
    setVerifyContent(false)
    setNewPasswordContent(true)
  }
  const onResend = async () => {
    return axios.post('auth/password/restore', {
      email: trimEmail,
    })
  }
  const onConfirm = async () => {
    accountStore.setVerifySetting({
      size: 6,
      title: '',
      onVerify,
      onResend,
      resendRemainingSeconds: 15,
      info1: `We’ve sent it to ${trimEmail}`,
      info2: 'Didn’t receive the code?',
    })
    setVerifyContent(true)
    try {
      await axios.post('auth/password/restore', {
        email: trimEmail,
      })
    } catch (e) {
      console.error(e)
    }
  }
  const onFocus = () => {
    setIsError(false)
  }
  const disabledConfirm =
    !trimEmail ||
    !trimEmail.includes('@') ||
    !trimEmail.includes('.') ||
    isError
  if (verifyContent) {
    return (
      <>
        <h2>Verify your identity</h2>
        <SizedBox height={40} />
        <VerifyContent fromForgotPage />
      </>
    )
  }
  if (newPasswordContent) {
    return <ForgotNewPassword email={trimEmail} verificationCode={code} />
  }
  return (
    <>
      <h2>Recover password</h2>
      <SizedBox height={8} />
      <p className={'par2 gray1 medium'}>
        Don’t worry, happens to the best of us. Enter the email address you use
        to log in to Call Loop.
      </p>
      <SizedBox height={32} />
      <InputText
        label={'Email address'}
        placeholder={'email@address.com'}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onClickRightIcon={() => setEmail('')}
        onFocus={onFocus}
        error={isError ? 'Please check entered email' : ''}
        focusOnMount
      />
      <SizedBox height={40} />
      <Button
        typeBtn={BtnType.primary}
        size={BtnSize.large}
        onClick={onConfirm}
        disabled={disabledConfirm}
      >
        Confirm
      </Button>

      <SizedBox height={16} />
      <FooterText>
        Need help? You can always reach out to{' '}
        <a href={appLinks.callLoopSupport} target={'_blank'} rel="noreferrer">
          Call Loop Support Team.
        </a>
      </FooterText>
    </>
  )
}
