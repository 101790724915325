import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import {
  CampaignListResponse,
  Contact,
  ContactBulkOperationRequestInput,
  Direction,
  EvaluateFilterQuery,
  FilterValueInput,
  GetListContactsQuery,
  GetMyOrganizationContactsQuery,
  Listing,
  Maybe,
} from 'src/generated/graphql'
import { getContactColumns } from 'components/NewTable/columns/contacts/contactColums'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import {
  contactListToOption,
  transformContact,
  transformContactLightResponse,
  transformListingToList,
} from 'store/contacts/functions'
import { contactListColumns } from 'components/NewTable/columns/contacts/config'
import { nanoid } from 'nanoid'
import { staticContactFieldsOptions } from 'store/contacts/mockData'
import billingStore from 'store/settings/billing/billingStore'
import { IContact } from 'src/types/IContact'
import { IContactList, IListResponse } from 'src/types/IContactList'
import { IOption } from 'src/types/IOption'
import { AppElements } from 'src/enums/appElements'
import { tableStatusToBack } from 'src/util/tableStatusToBack'

class ContactStore {
  tableStoreContacts: TableStore<IContact>
  tableStoreLists: TableStore<IListResponse>
  constructor() {
    this.tableStoreContacts = new TableStore({
      orderBy: 'createdAt',
      columns: getContactColumns(),
      tableName: 'ContactsTable',
      defaultHiddenColumn: [
        'firstName',
        'lastName',
        'listContacts',
        'optIn',
        ...customFieldsStore.customFields.map((field) => field.id),
      ],
      status: TableStatuses.totalCount,
      element: AppElements.Contact,
    })
    this.tableStoreLists = new TableStore({
      orderBy: 'createdAt',
      order: Direction.Desc,
      tableName: 'ContactListTable',
      columns: contactListColumns,
    })

    reaction(
      () => customFieldsStore.customFields,
      () => {
        runInAction(() => {
          this.tableStoreContacts.setColumns(getContactColumns(), [
            'firstName',
            'lastName',
            'listContacts',
            'optIn',
            ...customFieldsStore.customFields.map((field) => field.id),
          ])
        })
      }
    )
    reaction(
      () => billingStore.clSubscription?.limits?.contacts,
      (limit) => {
        if (limit) {
          this.maxContactCount = limit
        }
      }
    )
    makeAutoObservable(this)
  }

  maxContactCount = 1000
  openUnsubscribeContactModal = false
  contactsMap: Map<string, IContact> = new Map<string, IContact>()
  contactListsMap: Map<string | number, IContactList> = new Map<
    string,
    IContactList
  >()
  unsubscribedContactsListsIds: string[] = []
  detailContactId = ''
  contactFieldsOptions: IOption[] = staticContactFieldsOptions

  statisticTrigger = ''
  totalContactCount = 0

  listPageId: number | null = null
  contactFilter: FilterValueInput | null = null

  get contactsBulk(): ContactBulkOperationRequestInput {
    if (this.tableStoreContacts.isSelectALl) {
      return {
        listId: this.listPageId,
        filter: this.contactFilter,
        status: tableStatusToBack(this.tableStoreContacts.status),
        searchPattern: this.tableStoreContacts.search,
      }
    }
    return {
      ids: this.tableStoreContacts.checkedRowsIds,
    }
  }

  get contacts(): IContact[] {
    return Array.from(this.contactsMap.values())
  }

  get contactLists() {
    return Array.from(this.contactListsMap.values()).filter(
      (list) => !list.isNew
    )
  }

  get contactListOptions(): IOption[] {
    return this.contactLists.map(contactListToOption)
  }

  setListPageId = (id: typeof this.listPageId) => {
    this.listPageId = id
  }
  setContactFilter = (contactFilter: typeof this.contactFilter) => {
    this.contactFilter = contactFilter
  }

  setTotalContactCount(count: number) {
    this.totalContactCount = count
  }

  setStatisticTrigger() {
    this.statisticTrigger = nanoid()
  }

  // onCreateContactList(list: IContactList) {
  //   this.contactListsMap.set(list.id, list)
  // }
  updateContactList(list: IContactList) {
    this.contactListsMap.set(list.id, list)
  }
  updateContactLists(lists: Array<Maybe<Listing>>) {
    lists.forEach((list) => {
      if (list) {
        const newList = transformListingToList(list)
        this.updateContactList(newList)
      }
    })
  }
  deleteContactList(id: string) {
    this.contactListsMap.delete(id)
    runInAction(() => {
      this.tableStoreLists.checkedRows =
        this.tableStoreLists.checkedRows.filter((row) => row.id !== id)
    })
  }

  removeContactsFromLists(ids: string[], lists: IContactList[]) {
    lists.forEach((list) => {
      this.contactListsMap.set(list.id, {
        ...list,
        contacts: list.contacts.filter((id) => !ids.includes(id)),
      })
    })
  }

  setDataContacts(data: GetMyOrganizationContactsQuery) {
    this.contactsMap.clear()
    const transformContacts: IContact[] = []
    data.getMyOrganizationContacts?.content?.forEach((contact) => {
      if (contact) {
        const newContact = transformContactLightResponse(contact)
        transformContacts.push(newContact)
      }
    })
    this.tableStoreContacts.setTotal(
      data?.getMyOrganizationContacts?.total || 0
    )

    runInAction(() => {
      transformContacts.forEach((contact) => {
        this.contactsMap.set(contact.id, contact)
      })
    })
  }

  setDataListContacts(data: GetListContactsQuery) {
    this.contactsMap.clear()
    const transformContacts: IContact[] = []
    data.getListContacts?.content?.forEach((contact) => {
      if (contact) {
        const newContact = transformContactLightResponse(contact)
        transformContacts.push(newContact)
      }
    })
    this.tableStoreContacts.setTotal(data?.getListContacts?.total || 0)

    runInAction(() => {
      transformContacts.forEach((contact) => {
        this.contactsMap.set(contact.id, contact)
      })
    })
  }

  setSearchContactsData = (data: EvaluateFilterQuery) => {
    this.contactsMap.clear()
    const transformContacts: IContact[] = []
    this.tableStoreContacts.setTotal(data.evaluateFilter?.total)

    data?.evaluateFilter?.content?.forEach((contact) => {
      if (contact) {
        const newContact = transformContactLightResponse(contact)
        transformContacts.push(newContact)
      }
    })

    runInAction(() => {
      transformContacts.forEach((contact) => {
        this.contactsMap.set(contact.id, contact)
      })
    })
  }

  updateContact(contact: IContact) {
    this.contactsMap.set(contact.id, contact)
  }
  updateContacts(contacts: Array<Maybe<Contact>>) {
    contacts.forEach((contact) => {
      if (contact) {
        const newContact = transformContact(contact)
        this.updateContact(newContact)
      }
    })
  }

  onUpdateContacts = (bulk?: ContactBulkOperationRequestInput) => {
    if (bulk) {
      if (bulk.ids) {
        this.tableStoreContacts.setCheckedRows(
          this.tableStoreContacts.checkedRows.filter(
            (item) => !bulk.ids?.includes(item.id)
          )
        )
      } else {
        this.tableStoreContacts.setCheckedRows([])
      }
    }
    this.setStatisticTrigger()
    this.tableStoreContacts.onRefresh()
  }

  setOpenUnsubscribeContactModal(state: boolean) {
    this.openUnsubscribeContactModal = state
  }
  setUnsubscribedContactsListsIds(ids: string[]) {
    this.unsubscribedContactsListsIds = ids
  }
  onChangeContacts(ids: string[], info: Partial<IContact>) {
    const newContacts: IContact[] = []
    ids.forEach((id) => {
      const contact = this.contactsMap.get(id)
      if (contact) {
        newContacts.push({ ...contact, ...info })
      }
    })
    newContacts.forEach((newContact) => {
      this.contactsMap.set(newContact.id, newContact)
    })
  }
  addListing(lists: Listing[]) {
    const contactLists = lists.map(transformListingToList)
    contactLists.forEach((list) => {
      this.contactListsMap.set(list.id, list)
    })
  }
  addCampaignListResponse = (
    lists: Maybe<Array<Maybe<CampaignListResponse>>>
  ) => {
    lists?.forEach((list) => {
      if (list) {
        const old = this.contactListsMap.get(String(list.listId))
        if (!old) {
          this.contactListsMap.set(String(list.listId), {
            id: String(list.listId),
            createdAt: 0,
            name: list.listName || '',
            isNew: false,
            contacts: [],
            listWebFormsNames: '',
            listKeywords: [],
            listTriggers: [],
            activeContactsCount: 0,
            listKeywordsNames: '',
            invalidContactsCount: 0,
            unsubscribedContactsCount: 0,
            totalContactsCount: 0,
          })
        }
      }
    })
  }
}

export default new ContactStore()
