// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DA_4fmN4AYb54omXbpWQ{width:600px;padding:0 32px 32px 32px}.ALYfyFoaakjZ0FXYPzeg{display:grid;grid-template-columns:1fr 1fr;gap:12px;position:relative}.ALYfyFoaakjZ0FXYPzeg .qSEOfV3PfP6HQZgqOcuU{bottom:-22px;right:0;position:absolute}._pjUJNXSTnx3snrsHK92{margin-top:52px;display:flex;gap:12px}`, "",{"version":3,"sources":["webpack://./src/widgets/ConfigureAutoRecharge/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,wBAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,iBAAA,CACA,4CACE,YAAA,CACA,OAAA,CACA,iBAAA,CAIJ,sBACE,eAAA,CACA,YAAA,CACA,QAAA","sourcesContent":[".wrap{\n  width: 600px;\n  padding: 0 32px 32px 32px;\n}\n.wrapOptions{\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 12px;\n  position: relative;\n  .taxes{\n    bottom: -22px;\n    right: 0;\n    position: absolute;\n  }\n}\n\n.actions{\n  margin-top: 52px;\n  display: flex;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `DA_4fmN4AYb54omXbpWQ`,
	"wrapOptions": `ALYfyFoaakjZ0FXYPzeg`,
	"taxes": `qSEOfV3PfP6HQZgqOcuU`,
	"actions": `_pjUJNXSTnx3snrsHK92`
};
export default ___CSS_LOADER_EXPORT___;
